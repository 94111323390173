import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { useIntl } from 'react-intl-next';

import { Box, Stack } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { useAudioPlaybackState } from '../hooks/useAudioPlaybackState';
import { getContentUrl } from '../utils/getContentUrl';

import { MiniplayerControls } from './MiniplayerControls';
import { AudioProgressBar } from './AudioProgressBar';
import { TrackCard } from './TrackCard';

const SKIP_SECONDS = 15;
const START_SECONDS = 0;

export const Miniplayer = () => {
	const intl = useIntl();
	const [
		{ activeContent, audioSrcUrl, playbackSpeed },
		{ onContentStopped, onContentPaused, onContentResumed, onContentEnded, onContentError },
	] = useAudioPlaybackState();
	const audioRef = useRef<HTMLAudioElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [progress, setProgress] = useState(0);
	const { trackAIResultAction, trackAIResultView } = useAIEventsInstrumentation();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		const playAudio = async () => {
			const audio = audioRef.current;
			if (audio && audioSrcUrl && audio.src !== audioSrcUrl) {
				audio.src = audioSrcUrl;
				audio.load();
				audio.playbackRate = playbackSpeed;
				await audio.play();
			}
		};

		playAudio().catch((e) => onContentError(e));
	}, [audioSrcUrl, onContentStopped, onContentError, playbackSpeed]);

	useEffect(() => {
		trackAIResultView();

		const audio = audioRef.current;
		const container = containerRef.current;

		const onPaused = () => {
			onContentPaused();
		};

		const onPlayed = () => {
			onContentResumed();
		};

		const updateProgress = () => {
			if (audio && audio.duration) {
				setProgress(audio.currentTime / audio.duration);
			}
		};

		const onEnded = () => {
			setProgress(0);
			onContentEnded();
		};

		if (audio) {
			audio.addEventListener('pause', onPaused);
			audio.addEventListener('play', onPlayed);
			audio.addEventListener('ended', onEnded);
			audio.addEventListener('timeupdate', updateProgress);
		}

		if (container) {
			container.focus(); // Bring focus upon opening miniplayer
		}

		return () => {
			if (audio) {
				audio.removeEventListener('pause', onPaused);
				audio.removeEventListener('play', onPlayed);
				audio.removeEventListener('ended', onEnded);
				audio.removeEventListener('timeupdate', updateProgress);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const sendAnalyticsUIEvent = useCallback(
		(actionSubjectId: string, additionalAttributes?: Record<string, number | string>) => {
			const attributes = {
				audioType: activeContent?.sourceType,
				summaryLocale: intl.locale,
				...additionalAttributes,
			};
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					source: 'miniplayer',
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId,
					attributes,
				},
			}).fire();
			trackAIResultAction(`${actionSubjectId}Clicked`, { attributes });
		},
		[activeContent?.sourceType, createAnalyticsEvent, intl.locale, trackAIResultAction],
	);

	useEffect(() => {
		const audio = audioRef.current;
		if (audio) {
			sendAnalyticsUIEvent('audioPlayBackSpeed', { playbackSpeed });
			audio.playbackRate = playbackSpeed;
		}
	}, [playbackSpeed, sendAnalyticsUIEvent]);

	const onInitiatePause = () => {
		if (audioRef.current) {
			sendAnalyticsUIEvent('audioPause');
			audioRef.current.pause();
		}
	};

	const handleProgressChange = (newProgress: number) => {
		const audio = audioRef.current;
		if (audio && audio.duration) {
			sendAnalyticsUIEvent('audioSeek');
			audio.currentTime = newProgress * audio.duration;
			setProgress(newProgress);
		}
	};

	const onInitiateResume = async () => {
		if (audioRef.current) {
			sendAnalyticsUIEvent('audioPlay');
			await audioRef.current.play();
		}
	};

	const onInitiateSkipBack = () => {
		const audio = audioRef.current;
		if (audio) {
			sendAnalyticsUIEvent('audioSkipBackward');
			if (audio.currentTime <= SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
			} else {
				audio.currentTime -= SKIP_SECONDS;
			}
		}
	};

	const onInitiateSkipForward = () => {
		const audio = audioRef.current;
		if (audio && audio.duration) {
			sendAnalyticsUIEvent('audioSkipForward');
			if (audio.currentTime >= audio.duration - SKIP_SECONDS) {
				audio.currentTime = START_SECONDS;
				setTimeout(() => audio.pause(), 100); // Small delay to ensure current time is reset and rendered before pausing
			} else {
				audio.currentTime += SKIP_SECONDS;
			}
		}
	};

	const onInitiatePlayFromBeginning = async () => {
		const audio = audioRef.current;
		if (audio) {
			sendAnalyticsUIEvent('audioPlayFromBeginning');
			audio.currentTime = START_SECONDS;
			if (audio.paused) {
				await audio.play();
			}
		}
	};

	type CallbackType = (() => any) | (() => Promise<any>);
	const tryCallback = async (callback: CallbackType) => {
		try {
			await callback();
		} catch (e) {
			onContentError(e);
		}
	};

	const contentUrl = useMemo(() => {
		return activeContent ? getContentUrl(activeContent) : null;
	}, [activeContent]);

	const trackCardData = {
		contentType: activeContent?.contentType,
		contentSubType: activeContent?.contentSubType,
		contentTitle: activeContent?.contentTitle,
		spaceName: activeContent?.spaceName,
		product: 'Confluence',
	};

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
		<Box paddingBlock="space.050" tabIndex={0} ref={containerRef}>
			<Box>
				{/* eslint-disable-next-line jsx-a11y/media-has-caption -- The summary is already available in text so the track isn't very necessary */}
				<audio data-testid="miniplayer-audio" ref={audioRef} autoPlay />
				<Stack space="space.150">
					{contentUrl && <TrackCard {...trackCardData} />}
					<AudioProgressBar
						progress={progress}
						currentTime={audioRef?.current?.currentTime || 0}
						totalTime={audioRef?.current?.duration || 0}
						onProgressChange={handleProgressChange}
					/>
					<MiniplayerControls
						onPause={() => tryCallback(onInitiatePause)}
						onResume={() => tryCallback(onInitiateResume)}
						onSkipBack={() => tryCallback(onInitiateSkipBack)}
						onSkipForward={() => tryCallback(onInitiateSkipForward)}
						onPlayFromBeginning={() => tryCallback(onInitiatePlayFromBeginning)}
					/>
				</Stack>
			</Box>
		</Box>
	);
};
