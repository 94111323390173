import { createStore, createHook, createContainer } from 'react-sweet-state';

import {
	confluenceLocalStorageInstance as localStorage,
	keys as localStorageKeys,
} from '@confluence/storage-manager';

import { getSpeech } from '../api/speechAPI';
import type { AudioRequest } from '../types/audioRequestType';
import type { ActiveAudioContent } from '../types/activeAudioContent';

export interface AudioPlaybackState {
	audioContext?: AudioContext;
	audioSrcUrl?: string;
	activeContent?: ActiveAudioContent;
	abortController?: AbortController;
	isLoading: boolean;
	isPlaying: boolean;
	isClosed: boolean;
	isEnded: boolean;
	playbackSpeed: number;
	error?: Error;
}

type AudioPlaybackStateContainerProps = {
	initialAudioPlaybackState?: Partial<AudioPlaybackState>;
};

const AUDIO_TYPE = 'audio/mpeg';

const createAudioSourceEndedListener = (
	audioSource: AudioBufferSourceNode,
	dispatch: any,
): AbortController => {
	const abortController = new AbortController();
	audioSource.addEventListener(
		'ended',
		() => {
			dispatch(audioPlaybackActions.onContentEnded());
		},
		{ signal: abortController.signal },
	);

	return abortController;
};

const closeAudio = async (
	audioContext?: AudioContext,
	abortController?: AbortController,
	audioSrcUrl?: string,
) => {
	if (audioContext) {
		if (abortController) {
			abortController.abort();
		}

		await audioContext.close();
	}

	if (audioSrcUrl) {
		window.URL.revokeObjectURL(audioSrcUrl);
	}
};

export const audioPlaybackActions = {
	initialize:
		(initialState: Partial<AudioPlaybackState> = {}) =>
		async ({ setState, getState }) => {
			const { audioContext, abortController, audioSrcUrl } = getState();

			await closeAudio(audioContext, abortController, audioSrcUrl);

			setState(
				Object.assign(
					{
						audioContext: undefined,
						audioSrcUrl: undefined,
						activeContent: undefined,
						abortController: undefined,
						isLoading: false,
						isPlaying: false,
						isClosed: false,
						isEnded: false,
						error: undefined,
						playbackSpeed:
							Number(localStorage.getItem(localStorageKeys.AI_AUDIO_PLAYBACK_SPEED)) || 1,
					},
					initialState,
				),
			);
		},
	updateActiveContent:
		(activeContent: Partial<ActiveAudioContent>) =>
		({ setState, getState }) => {
			const { activeContent: activeContentFromState } = getState();
			if (activeContent) {
				setState({
					activeContent: {
						...activeContentFromState,
						...activeContent,
					},
				});
			}
		},
	onLoadStart:
		(activeContent: ActiveAudioContent) =>
		({ setState, getState }) => {
			const { activeContent: activeContentFromState } = getState();
			setState({
				activeContent: {
					...activeContentFromState,
					...activeContent,
				},
				isLoading: true,
			});
		},
	onLoadEnd:
		() =>
		({ setState }) => {
			setState({ isLoading: false });
		},
	onPlayContent:
		(audioRequest: AudioRequest, activeContent: ActiveAudioContent) =>
		async ({ getState, setState, dispatch }) => {
			await dispatch(audioPlaybackActions.initialize());
			dispatch(audioPlaybackActions.onLoadStart(activeContent));

			let audioContext: AudioContext | undefined;
			let abortController: AbortController | undefined;
			let audioSrcUrl: string | undefined;
			try {
				const speech = await getSpeech(audioRequest);
				audioSrcUrl = window.URL.createObjectURL(new Blob([speech], { type: AUDIO_TYPE }));
				// eslint-disable-next-line compat/compat
				audioContext = new window.AudioContext();
				const audioBuffer = await audioContext.decodeAudioData(speech);
				const audioSource = audioContext.createBufferSource();
				audioSource.buffer = audioBuffer;
				abortController = createAudioSourceEndedListener(audioSource, dispatch);
				audioSource.connect(audioContext.createMediaStreamDestination());

				if (getState().isClosed) {
					await closeAudio(audioContext, abortController, audioSrcUrl);
				} else {
					const { activeContent: activeContentFromState } = getState();
					setState({
						audioContext,
						audioSrcUrl,
						activeContent: {
							...activeContentFromState,
							...activeContent,
						},
						abortController,
						isPlaying: true,
						isEnded: false,
					});
				}
			} catch (e) {
				await closeAudio(audioContext, abortController, audioSrcUrl);
				setState({
					error: e,
					audioContext: undefined,
					activeContent: undefined,
				});
			}

			dispatch(audioPlaybackActions.onLoadEnd());
		},
	onContentPaused:
		() =>
		({ setState }) => {
			setState({
				isPlaying: false,
			});
		},
	onContentResumed:
		() =>
		({ setState }) => {
			setState({
				isPlaying: true,
				isEnded: false,
			});
		},
	onContentPlaybackSpeedChanged:
		(speed: number) =>
		({ setState }) => {
			localStorage.setItem(localStorageKeys.AI_AUDIO_PLAYBACK_SPEED, speed);
			setState({ playbackSpeed: speed });
		},
	onContentStopped:
		() =>
		async ({ dispatch, setState }) => {
			await dispatch(audioPlaybackActions.initialize());
			setState({ isClosed: true });
		},
	onContentEnded:
		() =>
		({ setState }) => {
			setState({
				isEnded: true,
				isPlaying: false,
			});
		},
	onContentError:
		(error: Error) =>
		({ setState }) => {
			setState({
				error,
			});
		},
};

export type AudioPlaybackActions = typeof audioPlaybackActions;

const Store = createStore<AudioPlaybackState, any>({
	initialState: {
		audioContext: undefined,
		audioSrcUrl: undefined,
		activeContent: undefined,
		abortController: undefined,
		isLoading: false,
		isPlaying: false,
		isClosed: false,
		isEnded: false,
		playbackSpeed: 1,
		error: undefined,
	},
	actions: audioPlaybackActions,
	name: 'listenerState',
});

export const AudioPlaybackStateContainer = createContainer<
	AudioPlaybackState,
	AudioPlaybackActions,
	AudioPlaybackStateContainerProps
>(Store, {
	onInit:
		() =>
		async ({ dispatch }, { initialAudioPlaybackState }) => {
			void dispatch(audioPlaybackActions.initialize(initialAudioPlaybackState));
		},
});
export const useAudioPlaybackState = createHook<AudioPlaybackState, AudioPlaybackActions>(Store);
