import { cfetch } from '@confluence/network';

import type { AudioRequest } from '../types/audioRequestType';

export const AUDIO_SUMMARY_API_ROOT = '/gateway/api/v1/audio/summary';

export const getSpeech = async ({
	pageAri,
	locale,
	activationId,
}: AudioRequest): Promise<ArrayBuffer> => {
	const response = await cfetch(AUDIO_SUMMARY_API_ROOT, {
		method: 'POST',
		body: JSON.stringify({
			resource_ari: pageAri,
			locale,
		}),
		headers: {
			'Content-Type': 'application/json',
			'ATL-ActivationId': activationId,
		},
	});

	return response.arrayBuffer();
};
