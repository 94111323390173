import type { FC } from 'react';
import React, { useContext, useRef } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { useConfluencePageAri } from '@atlassian/ai-summary';

import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Inline } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import ListBulletedIcon from '@atlaskit/icon/core/list-bulleted';
import AddIcon from '@atlaskit/icon/core/add';
import CommentIcon from '@atlaskit/icon/core/comment';
import QuotationMarkIcon from '@atlaskit/icon/core/quotation-mark';
import ClockIcon from '@atlaskit/icon/core/clock';
import VolumeHighIcon from '@atlaskit/icon/core/volume-high';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { AIPresentationModalWrapper } from '@confluence/ai-presentation-generation';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { useSessionData } from '@confluence/session-data';
import { fg } from '@confluence/feature-gating';
import { CommentCountsContext } from '@confluence/comment-context';
import { useRightSidebarContext } from '@confluence/page-layout-context';
import {
	BULK_ISSUE_CREATE_SIDE_PANEL_ID,
	BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH,
	constructInsertStorageFragmentRequestPayload,
	getPageContentRange,
	getValidRange,
	useSetInsertStorageFragmentRequestPayload,
	WarningDialog,
	useSetIsCreatedWithAi,
	useWarningDialogStateActions,
	useGetContentLastModifiedTimestamp,
	useSetIsCreatedFromTable,
} from '@confluence/issue-create-side-panel';
import { convertSelectionToLegacyFormat } from '@confluence/highlight-actions';
import { expVal } from '@confluence/feature-experiments';
import { useShowAudioPanel, useAudioPlaybackState } from '@confluence/audio';

import { PopupStateTypes } from './popupStateTypes';

export const i18n = defineMessages({
	inlineCommentsMenuItemLabel: {
		id: 'ai-floating-context-menu.menu.item.label.inline-comments',
		defaultMessage: 'Summarize comments',
		description: 'Label for the AI Content Actions Inline Comments Menu Item',
	},
	pageCatchupMenuItemLabel: {
		id: 'ai-floating-context-menu.menu.item.label.page-catchup',
		defaultMessage: 'Summarize changes',
		description: 'Label for the AI Content Actions Page Catchup Menu Item',
	},
	aiPresentationGenerationMenuItemLabel: {
		id: 'ai-floating-context-menu.menu.item.label.ai-presentation-generation',
		defaultMessage: 'Rewrite as presentation',
		description: 'Label for the AI Content Actions Presentation Generation Menu Item',
	},
	audioSummarySectionLabel: {
		id: 'ai-floating-context-menu.menu.item.label.audio-summary',
		defaultMessage: 'Listen to Page Summary',
		description: 'Label for the audio summary menu item',
	},
	bulkIssueCreateMenuItemLabel: {
		id: 'ai-floating-context-menu.menu.item.label.bulk-issue-create',
		defaultMessage: 'Create Jira issues',
		description: 'Label for the Jira Bulk Issue Create Menu Item',
	},
	betaLabel: {
		id: 'ai-floating-context-menu.menu.item.lozenge.beta',
		defaultMessage: 'Beta',
		description:
			'Text for the Beta lozenge that appears to the right for action menu items that are in beta phase',
	},
	summarizeSectionLabel: {
		id: 'ai-floating-context-menu.summarize.section.label',
		defaultMessage: 'Summarize',
		description: 'Label for the summarize section in the menu group',
	},
	createSectionLabel: {
		id: 'ai-floating-context-menu.create.section.label',
		defaultMessage: 'Create',
		description: 'Label for the create section in the menu group',
	},
});

enum ContentActionsMenuSectionKey {
	Summarize = 'Summarize',
	Create = 'Create',
}

type ContentActionsMenuSection = {
	key: ContentActionsMenuSectionKey;
	label: string;
	showSection: boolean;
};

type ContentActionsMenuItemType = {
	key: PopupStateTypes;
	label: string;
	showMenuItem: boolean;
	isDisabled: boolean;
	beta: boolean;
	section: ContentActionsMenuSectionKey;
	icon?: React.ReactNode;
};

type AIFloatingContextMenuGroupProps = {
	contentId: string;
	contentType: string;
	spaceKey: string;
	isLivePage?: boolean;
	setIsOpen: (state: boolean) => void;
	setPopupState: (popupKey: PopupStateTypes) => void;
};

export const AIFloatingContextMenuGroup: FC<AIFloatingContextMenuGroupProps> = ({
	contentId,
	contentType,
	spaceKey,
	isLivePage = false,
	setIsOpen,
	setPopupState,
}) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { unresolvedInlineCommentsCount, footerCommentsCount } = useContext(CommentCountsContext);
	const { edition, cloudId, activationId } = useSessionData();
	const { showModal } = useDialogs();
	const rightSidebarContext = useRightSidebarContext();
	const setIsCreatedWithAi = useSetIsCreatedWithAi();
	const setIsCreatedFromTable = useSetIsCreatedFromTable();
	const setInsertStorageFragmentRequestPayload = useSetInsertStorageFragmentRequestPayload();
	const contentLastModifiedTimestamp = useGetContentLastModifiedTimestamp();
	const { showWarningDialog } = useWarningDialogStateActions();
	const { showAudioPanel } = useShowAudioPanel();
	const [, { onPlayContent }] = useAudioPlaybackState();
	const { pageAri } = useConfluencePageAri(cloudId, contentId, contentType);

	const activeContent = {
		id: contentId,
		contentType,
		spaceKey,
	};

	const isSidePanelOpen =
		rightSidebarContext.getCurrent()?.panelId === BULK_ISSUE_CREATE_SIDE_PANEL_ID;

	const content = useRef<HTMLDivElement | null>(
		typeof document !== 'undefined' ? document.querySelector('div#content') : null,
	);

	const menuItems: ContentActionsMenuItemType[] = [
		{
			key: PopupStateTypes.QuickSummary,
			label: intl.formatMessage(i18n.summarizeSectionLabel),
			showMenuItem: true,
			isDisabled: false,
			beta: false,
			section: ContentActionsMenuSectionKey.Summarize,
			icon: <ListBulletedIcon label="" />,
		},
		{
			key: PopupStateTypes.QuickSummaryAudio,
			label: intl.formatMessage(i18n.audioSummarySectionLabel),
			showMenuItem: expVal('confluence_frontend_audio_ai_summary_web', 'isEnabled', false),
			isDisabled: false,
			beta: true,
			section: ContentActionsMenuSectionKey.Summarize,
			icon: <VolumeHighIcon label="" />,
		},
		{
			key: PopupStateTypes.PageCatchup,
			label: intl.formatMessage(i18n.pageCatchupMenuItemLabel),
			showMenuItem: fg('consumption_ai_page_catchup_ga'),
			isDisabled: false,
			beta: false,
			section: ContentActionsMenuSectionKey.Summarize,
			icon: <ClockIcon label="" />,
		},
		{
			key: PopupStateTypes.Comments,
			label: intl.formatMessage(i18n.inlineCommentsMenuItemLabel),
			showMenuItem: true,
			isDisabled: isLivePage
				? unresolvedInlineCommentsCount === 0
				: unresolvedInlineCommentsCount === 0 && footerCommentsCount === 0,
			beta: false,
			section: ContentActionsMenuSectionKey.Summarize,
			icon: <CommentIcon label="" />,
		},
		{
			key: PopupStateTypes.AIPresentationGeneration,
			label: intl.formatMessage(i18n.aiPresentationGenerationMenuItemLabel),
			showMenuItem: fg('confluence_ai_presentation_generation'),
			isDisabled: false,
			beta: true,
			section: ContentActionsMenuSectionKey.Create,
			icon: <QuotationMarkIcon label="" />,
		},
		{
			key: PopupStateTypes.BulkIssueCreate,
			label: intl.formatMessage(i18n.bulkIssueCreateMenuItemLabel),
			showMenuItem: fg('confluence_ai_bulk_issue_creation') || fg('cc_ai_bulk_issue_create'),
			isDisabled: false,
			beta: false,
			section: ContentActionsMenuSectionKey.Create,
			icon: <AddIcon label="" />,
		},
	];

	const sectionItems: ContentActionsMenuSection[] = [
		{
			key: ContentActionsMenuSectionKey.Summarize,
			label: intl.formatMessage(i18n.summarizeSectionLabel),
			showSection: menuItems.some((item) => item.section === 'Summarize' && item.showMenuItem),
		},
		{
			key: ContentActionsMenuSectionKey.Create,
			label: intl.formatMessage(i18n.createSectionLabel),
			showSection:
				!isLivePage && menuItems.some((item) => item.section === 'Create' && item.showMenuItem),
		},
	];

	const onMenuItemSelection = (key: PopupStateTypes) => {
		let additionalAnalyticsAttributes = {};

		if (key === PopupStateTypes.Comments) {
			additionalAnalyticsAttributes = {
				numPageCommentsSummarized: footerCommentsCount,
				numInlineCommentsSummarized: unresolvedInlineCommentsCount,
			};
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'page',
				action: 'clicked',
				actionSubject: 'menuItem',
				actionSubjectId: `aiActionsMenuItem-${key}`,
				attributes: {
					contentId,
					edition,
					...additionalAnalyticsAttributes,
					isLivePage,
				},
			},
		}).fire();

		if (key === PopupStateTypes.BulkIssueCreate && fg('confluence_ai_bulk_issue_creation')) {
			if (isSidePanelOpen) {
				showWarningDialog(WarningDialog.FLOATING);
			} else {
				setIsOpen(false);
				void setIsCreatedWithAi(true);
				void setIsCreatedFromTable(false);
				const range = getValidRange(getPageContentRange());
				const payload = constructInsertStorageFragmentRequestPayload(
					contentId,
					convertSelectionToLegacyFormat,
					range,
					content.current,
					contentLastModifiedTimestamp,
				);
				void setInsertStorageFragmentRequestPayload(payload);
				rightSidebarContext.open(
					BULK_ISSUE_CREATE_SIDE_PANEL_ID,
					BULK_ISSUE_CREATE_SIDE_PANEL_WIDTH,
				);
			}
		} else if (key === PopupStateTypes.QuickSummaryAudio) {
			showAudioPanel();
			const audioRequest = {
				pageAri,
				locale: intl.locale,
				activationId,
			};
			onPlayContent(audioRequest, activeContent);
		} else if (key === PopupStateTypes.AIPresentationGeneration) {
			showModal(AIPresentationModalWrapper, {
				contentId,
				contentType,
				spaceKey,
			});
			setIsOpen(false);
			setPopupState(PopupStateTypes.Menu);
		} else {
			setPopupState(key);
		}
	};

	return (
		<MenuGroup testId="ai-uber-button-menu-group">
			{sectionItems.map((section) =>
				section.showSection ? (
					<Section key={section.key} title={section.label}>
						{menuItems.map((item) =>
							item.showMenuItem && item.section === section.key ? (
								<ButtonItem
									iconBefore={item.icon}
									key={item.key}
									isDisabled={item.isDisabled}
									onClick={() => {
										onMenuItemSelection(item.key);
									}}
								>
									<Inline spread="space-between" alignBlock="center">
										{item.label}
										{item.beta && <Lozenge>{intl.formatMessage(i18n.betaLabel)}</Lozenge>}
									</Inline>
								</ButtonItem>
							) : null,
						)}
					</Section>
				) : null,
			)}
		</MenuGroup>
	);
};
